import { createSelector } from '@ngrx/store';
import { selectAuthState } from '../../store/core.state';
import { AuthState, UserRole } from './auth.model';
import { User } from 'firebase/auth';

export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state);

export const selectLoggedIn = createSelector(selectAuth, (state: AuthState) => !!state.user);

export const selectUser = createSelector(selectAuth, (state: AuthState) => {
  return state.user;
});

export const selectEmail = createSelector(selectUser, (user: User) => {
  return user.email;
});

export const selectPhotoURL = createSelector(selectAuth, (state: AuthState) => {
  return state.user?.photoURL;
});

export const selectRoles = createSelector(selectAuth, (state: AuthState) => {
  return state.roles;
});

export const selectCurrentRole = createSelector(selectRoles, (roles: UserRole[]) => {
  const selectedRole = window.localStorage.getItem('selectedRole');

  if (selectedRole) {
    return selectedRole as UserRole;
  }

  if (roles.length > 0) {
    return roles[0];
  }

  return undefined;
});

export const selectHasMultipleRoles = createSelector(selectRoles, (roles: UserRole[]) => {
  return roles?.length > 1;
});

export const selectRolesLoading = createSelector(selectAuth, (state: AuthState) => {
  return state.rolesLoading;
});

export const selectRolesLoaded = createSelector(selectAuth, (state: AuthState) => {
  return state.rolesLoaded;
});
