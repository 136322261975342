import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { NotificationActions } from './notification.actions';
import { NotificationDataService } from './notification-data.service';
import { NotificationService } from 'src/app/core/notification/notification.service';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private notificationDataService: NotificationDataService
  ) {}

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getAll),
      mergeMap(() =>
        this.notificationDataService.getAll().pipe(
          map((notifications) => NotificationActions.getAllSuccess({ notifications: notifications })),
          catchError((error) => of(NotificationActions.getAllError({ error: error })))
        )
      )
    )
  );

  getAllError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.getAllError),
        tap(() => this.notificationService.error('Ops. Qualcosa è andato storto durante il caricamento dei dati'))
      ),
    { dispatch: false }
  );
}
