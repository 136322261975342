export const FEATURE_NAME = 'check-detail';

export interface CheckState {
  checks?: CustomerCheck[];
  loading?: boolean;
  loaded?: boolean;
  error?: Error;
}
export interface CustomerCheck {
  id: number;
  date: Date;
  averageWeight?: number;
  averageSteps?: number;
  status: CheckStatus;
  dataLoaded: boolean;
}

export enum CheckStatus {
  Locked = 'Locked',
  Running = 'Running',
  Completed = 'Completed',
  Skipped = 'Skipped'
}

export enum CheckFrequency {
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Monthly = 'Monthly'
}
