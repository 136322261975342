import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { UserDataDataService } from './user-data-data.service';
import { UserDataActions } from './user-data.actions';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/notification/notification.service';

@Injectable()
export class UserDataEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private userDataDataService: UserDataDataService,
    private dialog: MatDialog
  ) {}

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.save),
      mergeMap((action) =>
        this.userDataDataService.save(action.date, action.data).pipe(
          map(() => UserDataActions.saveSuccess()),
          catchError((error) => of(UserDataActions.saveError({ error: error })))
        )
      )
    )
  );

  saveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataActions.saveSuccess),
        tap(() => {
          this.dialog.closeAll();
          this.notificationService.success('Dati aggiornati correttamente!');
        })
      ),
    { dispatch: false }
  );

  saveError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataActions.saveError),
        tap(() => this.notificationService.error('Ops. Qualcosa è andato storto durante il salvataggio dei dati.'))
      ),
    { dispatch: false }
  );

  loadDatesWithData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.loadDatesWithData),
      mergeMap((action) =>
        this.userDataDataService.loadDatesWithData(action.year, action.month).pipe(
          map((dates) => UserDataActions.loadDatesWithDataSuccess({ dates: dates })),
          catchError((error) => of(UserDataActions.loadDatesWithDataError({ error: error })))
        )
      )
    )
  );

  loadDataByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.loadDataByDate),
      mergeMap((action) =>
        this.userDataDataService.loadDataByDate(action.date).pipe(
          map((data) => UserDataActions.loadDataByDateSuccess({ data: data })),
          catchError((error) => of(UserDataActions.loadDataByDateError({ error: error })))
        )
      )
    )
  );

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.loadUserData),
      mergeMap(() =>
        this.userDataDataService.loadUserData().pipe(
          map((userData) => UserDataActions.loadUserDataSuccess({ userData: userData })),
          catchError((error) => of(UserDataActions.loadUserDataError({ error: error })))
        )
      )
    )
  );
}
