import { Injectable } from '@angular/core';
import { tap, withLatestFrom } from 'rxjs';
import { RequestActions } from './request.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/core.state';
import { selectRequests, selectRequestsCounter, selectLoading } from './request.selectors';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  readonly requests$ = this.store.select(selectRequests);
  readonly requestsCounter$ = this.store.select(selectRequestsCounter);
  readonly loading$ = this.store.select(selectLoading);

  constructor(private store: Store) {}

  canActivate() {
    return (<Store<AppState>>(<unknown>this.store)).pipe(
      withLatestFrom(this.loading$),
      tap(([, loading]) => {
        if (!loading) {
          this.store.dispatch(RequestActions.getAll());
        }
      })
    );
  }

  accept(id: number) {
    this.store.dispatch(RequestActions.accept({ id: id }));
  }

  deny(id: number) {
    this.store.dispatch(RequestActions.deny({ id: id }));
  }

  loadRequestCounter() {
    this.store.dispatch(RequestActions.loadRequestsCounter());
  }
}
