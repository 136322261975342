import { Injectable } from '@angular/core';
import { UserDataActions } from './user-data.actions';
import { Store } from '@ngrx/store';
import { DailyUserData, UserData } from './user-data.model';
import { Observable, tap, withLatestFrom } from 'rxjs';
import {
  selectDataByDate,
  selectDatesWithData,
  selectLoadingDataByDate,
  selectLoadingDatesWithData,
  selectLoadingUserData,
  selectUserData
} from './user-data.selectors';
import { AppState } from 'src/app/core/store/core.state';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  readonly datesWithData$: Observable<Date[]> = this.store.select(selectDatesWithData);
  readonly loadingDatesWithData$: Observable<boolean> = this.store.select(selectLoadingDatesWithData);

  readonly dataByDate$: Observable<DailyUserData> = this.store.select(selectDataByDate);
  readonly loadingDataByDate$: Observable<boolean> = this.store.select(selectLoadingDataByDate);

  readonly userData$: Observable<UserData> = this.store.select(selectUserData);
  readonly loadingUserData$: Observable<boolean> = this.store.select(selectLoadingUserData);

  constructor(private store: Store) {}

  canActivate() {
    return (<Store<AppState>>(<unknown>this.store)).pipe(
      withLatestFrom(this.loadingUserData$),
      tap(([, loading]) => {
        if (!loading) {
          this.store.dispatch(UserDataActions.loadUserData());
        }
      })
    );
  }

  save(date: Date, data: DailyUserData) {
    this.store.dispatch(UserDataActions.save({ date: date, data: data }));
  }

  loadDatesWithData(year?: number, month?: number) {
    this.store.dispatch(
      UserDataActions.loadDatesWithData({
        year: year ?? new Date().getFullYear(),
        month: month ?? new Date().getMonth() + 1
      })
    );
  }

  loadDataByDate(date: Date) {
    this.store.dispatch(
      UserDataActions.loadDataByDate({
        date: date
      })
    );
  }
}
